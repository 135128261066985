<template>
  <div class="modal modal_wrap">
    <div class="modal_popup">
      <h2 class="text_white text_center">Delete set</h2>
      <p class="text text_white text_center">Set contains packages.</p>
      <p class="text text_white text_center">Please choose from the following options.</p>
      <button class="btn btn_transparent btn_full" @click="removeFull">Delete Set and Packages</button>
      <button class="btn btn_transparent btn_full" @click="remove">Delete Set only and keep Packages</button>
      <button class="btn btn_transparent btn_full" @click="$parent.showRemoveModal = false"><svg-icon name="solid/times" /> Cancel</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "WarningModal",
    props: {
      setId: Number
    },
    methods: {
      removeFull() {
        this.$store.dispatch('sets/delete', {id: this.setId, with_packages: true});
        this.$store.dispatch('sets/getSets');
        this.$parent.showRemoveModal = false;
      },
      remove() {
        this.$store.dispatch('sets/delete', {id: this.setId, with_packages: false});
        this.$store.dispatch('sets/getSets');
        this.$parent.showRemoveModal = false;
      }
    }
  }
</script>

<style scoped>
 .btn{
   margin: 10px 0;
   width: 100%;
   max-width: none;
 }
</style>
